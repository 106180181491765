import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

type Image = {
  id: string;
  altText: string;
  asset: {
    gatsbyImageData: IGatsbyImageData;
  };
};

type Images = Array<Image>;

type SanityGallery = { images: Images };

type SanityGalleryResult = { sanityGallery: SanityGallery };

function useSanityGallery(): Images {
  const { sanityGallery } = useStaticQuery<SanityGalleryResult>(graphql`
    query SanitySiteGalleryQuery {
      sanityGallery {
        images {
          id: _key
          altText
          asset {
            gatsbyImageData(width: 700)
          }
        }
      }
    }
  `);
  return sanityGallery.images;
}

export { useSanityGallery };
export type { Image, Images, SanityGallery, SanityGalleryResult };
