import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import chunk from 'lodash.chunk';
import { nanoid } from 'nanoid';
import * as React from 'react';

import { ContactSection } from '../components/contact-section';
import { Hero } from '../components/hero';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { Button } from '../components/ui/button';
import { ButtonLink } from '../components/ui/button-link';
import { useSanityGallery } from '../hooks/use-sanity-site-gallery';
import { Wave } from '../icons/wave';

function GalleryPage(): React.ReactElement {
  return (
    <>
      <SEO title="Gallery Page" />
      <Layout>
        <Hero
          image={
            <StaticImage
              src="../images/gallery-hero.jpg"
              alt=""
              className="flex-1 object-cover"
            />
          }
          heading={
            <h1 className="text-6xl">
              <span className="font-semibold">
                Quality Early <br />
                Childhood Education <br />
              </span>
              in Lake Cathie
            </h1>
          }
          cta={
            <ButtonLink variant="teal" to="#contact">
              Enquire Today
            </ButtonLink>
          }
        />
        <OurGallery />
        <ContactSection />
      </Layout>
    </>
  );
}

function OurGallery(): React.ReactElement {
  const galleryImage = useSanityGallery();

  const groupedImages = React.useRef([chunk(galleryImage, 3).slice(0, 3)]);

  const [activeImages, setActiveImages] = React.useState(groupedImages.current);

  const [viewMore, setViewMore] = React.useState(true);

  React.useEffect(() => {
    if (chunk(chunk(galleryImage, 3), 3).length <= 1) {
      setViewMore(false);
    }
  }, [galleryImage]);

  const handleClick = (): void => {
    setActiveImages(chunk(chunk(galleryImage, 3), 3));
    setViewMore(false);
  };

  const rowSpans = [
    // eslint-disable-next-line sonarjs/no-duplicate-string
    ['row-span-4', 'row-span-4', 'row-span-3'],
    ['row-span-5', 'row-span-2', 'row-span-4'],
    ['row-span-3', 'row-span-2', 'row-span-6'],
  ];

  return (
    <div className="relative text-brown">
      <Wave className="text-white text-opacity-50" />
      <div className="px-4 bg-white bg-opacity-50 sm:px-6 lg:px-8">
        <div className="w-full max-w-screen-lg mx-auto">
          <h2 className="text-4xl text-center">
            <span className="font-semibold">Our Gallery</span>
          </h2>

          {activeImages.map((group) => (
            <div
              key={nanoid()}
              className="relative mt-2 aspect-w-4 aspect-h-6 md:aspect-w-10 md:aspect-h-10"
            >
              <div className="absolute inset-0 flex">
                <div className="flex-1">
                  <div className="grid h-full grid-flow-col grid-cols-2 gap-2 md:grid-cols-3 grid-rows-11">
                    {group.map((images, i) => {
                      return images.map((img, e) => (
                        <div
                          key={img.id}
                          className={`relative bg-gray-400 ${rowSpans[i][e]}`}
                        >
                          <figure className="absolute inset-0 flex">
                            <GatsbyImage
                              image={img.asset.gatsbyImageData}
                              className="flex-1 object-cover"
                              alt={img.altText ?? ''}
                            />
                          </figure>
                        </div>
                      ));
                    })}
                  </div>
                </div>
              </div>
            </div>
          ))}

          {viewMore && (
            <p className="mt-12 text-center">
              <Button onClick={handleClick}>View More</Button>
            </p>
          )}
        </div>
      </div>
      <div className="bg-white bg-opacity-50">
        <Wave className="text-white" />
      </div>
    </div>
  );
}

export default GalleryPage;
